@import 'variables.pcss';

.access-limitation {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  padding: 12px;

  &-modal {
    height: fit-content;
  }

  &-description{
    white-space: break-spaces;
    max-width: 500px;
    &-header{
      font-size: 18px;
      margin-bottom: 25px;
      position: relative;
    }
    &-check{
      color: var(--add-button-color);
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-addon {
    margin-bottom: 12px;
  }

  &-checkbox{
    margin: 16px 0;
  }

  &-border{
    border: 2px solid var(--add-button-color);
    border-radius: 8px;
    padding: 24px;
  }
}
