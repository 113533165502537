@import 'variables.pcss';

.drop-styles {
  padding: 0;
  border: 0;
  cursor: pointer;
}

.form {
  min-width: 610px;
  box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0, 0, 0, .28);
  margin: 20px;
  padding: 10px 15px;
  background-color: var(--light-background-color);
  position: relative;
  display: flex;
  min-height: 663px;
  flex-grow: 1;

  &-empty {
    width: 100%;
    &-text {
      margin-top: 50px;
      text-align: center;
      margin-left: 10px;
      font-size: 15pt;
    }
    &-note {
      margin-top: 50px;
      margin-left: 10px;
      font-size: 12pt;
      display: flex;
      flex-direction: row;
      &-text {
        margin-top: 5px;
      }
    }
    &-step-bubble {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      padding: 4px;
      margin-right: 10px;

      background: #fff;
      border: 2px solid #666;
      color: #666;
      text-align: center;

      font: 14px Arial, sans-serif;
    }
    &-app-container {
      margin-top: 50px;
      font-size: 12pt;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    &-app {
      color: var(--main-color);
      font-size: 25pt;
      margin-right: 10px;
      &-text {
        margin-top: 8px;
        text-align: center;
      }
    }
  }
}

.paper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 20px;
  background-color: var(--grey-8-color);
  border-radius: 8px;
  width: 324px;

  &-flex {
    flex: 1;
  }

  &-actions {
    height: 102px;
  }

  &-picture {
    cursor: pointer;
    max-width: 324px !important;
    max-height: 446px;

    canvas {
      max-width: 324px !important;
      max-height: 1000px !important;
      object-fit: contain !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 100;
    }

    span {
      display: inline-block;
      position: inherit;
      left: 0;
      top: 0;
      width: 100%;
      max-width: 324px;
      height: 20px;
      background-color: rgba(45, 183, 183, 0.54);
      z-index: 500;
      transform: translateY(135%);
      animation: move 3.0s cubic-bezier(0.15, 0.44, 0.76, 0.64);
      animation-iteration-count: infinite;
    }
    @keyframes move {
      0%, 100% { transform: translateY(-1400%); }
      50% { transform: translateY(0%); }
      75% { transform: translateY(-2800%); }
    }
  }

  &-button {
    margin: 10px 15px;
    width: 370px;
  }

  &-button-margin {
    margin-top: 10px;
  }
}

.emptyUpload {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &-button {
    width: 370px;
    cursor: pointer;
  }
}

.vehicleForm {
  width: 100%;
  max-width: 724px;
}

.vehicleFormWithImage{
  display: flex;
  flex-flow: row;

  .paper{
    margin: 0 0 0 75px;
    width: 400px;
    height: 600px;

    &-picture{
      max-width: 400px !important;
      max-height: 600px;
    }
  }
}


.uploadView{
  &-header{
    font-weight: 400;
    font-size: 18px;
  }

  &-info{
    font-size: 14px;
    margin-top: 24px;
    max-width: 448px;

    div{
      display: flex;
      gap: 8px;
    }

    span{
      color: var(--primary-color);
    }

    div:not(:first-child) {
      margin-top: 16px;
    }
  }

  &-dropZone{
    margin-top: 38px;
    border-radius: 8px;

    &-content{
      width: 100%;
      height: 282px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      font-size: 14px;
      font-weight: 400;

      &-main{
        margin-top: 24px;
        margin-bottom: 8px;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}

.footerMargin{
  margin-top: 38px;
}

.clientView{
  width: 100%;
  &-formAndImage{
    display: flex;
    gap: 48px;
  }
}

.compareView{
  &-text{
    font-weight: 400;
    font-size: 16px;

    div:not(:first-child) {
      margin-top: 16px;
    }
  }

  &-option{
    margin-top: 16px;
    
    div:first-child{
      color: var(--grey-4-color);
      font-size: 12px;
    }
  }

  &-options{

    &-andPreview{
      margin-top: 32px;
      display: flex;
      gap: 48px;
      flex-flow: row;
    }

    &-title{
      display: flex;
      width: 462px;
      font-size: 18px;
  
      div {
        width: 100%;
      }
    }
  }

}

.alreadyExists{
  &-title{
    font-size: 18px;
  }
  &-description{
    margin-top: 32px;
    font-size: 16px;
  }
}

.customerList{
  margin-top: 16px;
  max-height: 123px;
  height: 123px;
  overflow: auto;
  max-width: 1160px;

  &-entry{
    min-height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey-8-color);
    user-select: none;
    cursor: pointer;
    padding: 0px 16px;

    &:hover{
      background-color: var(--primary-lighter-color);
    }

    &-active{
      background-color: var(--primary-light-color) !important;
    }

    &-content{
      margin: 17px;
      display: grid;
      grid-gap: 21px;
      grid-template-columns: minmax(100px, 1fr) 75px 50px 125px 125px 1fr 1fr;
      width: 100%;
      

      * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      
      &-header{
        margin: 17px;
        width: 100%;
      }
    }
  }
}

.button{
  &-icon{
    margin-right: 12px;
  }
}

.addonManager{
  box-shadow: none;
}

.title{
  display: flex;
  gap: 32px;

  &-icon{
    cursor: pointer;
  }
}

.loading {
  max-width: 400px;
  min-width: 250px;
  margin-top: 14px;

  &-anim{
    &-container{
      position: relative;
      min-height: 140px;
    }
    &-image{
      border-radius: 5px;
      max-width: calc(100% - 10px);
      margin: 5px;
    }
    &-animation{
      position: absolute;
      height: 100%;
      width: 100%;
      &-bar {
        position:absolute;
        width: 10px;
        height: calc( 100% - 5px );
        top: 2px;
        left: 0;
        bottom: 0;
        background: var(--add-button-color);
        box-shadow: 0 0 80px 20px var(--add-button-color);
        clip-path: inset(0);
        animation: move 1s ease-in-out infinite alternate, shadowClip 2s ease-in-out infinite;
      }
      &:before,
      &:after,
      &-brackets:after,
      &-brackets:before {
        border-color: var(--add-button-color);
        content: "";
        position: absolute;
        width: 45px;
        height: 46px;
        border-style: solid;
        border-width: 0px;
      }
      &:before {
        left: 0;
        top: 0;
        border-left-width: 5px;
        border-top-width: 5px;
        border-radius: 5px 0 0 0;
      }
      &:after {
        right: 0;
        top: 0;
        border-right-width: 5px;
        border-top-width: 5px;
        border-radius: 0 5px 0 0;
      }
      &-brackets {
        &:before {
          left: 0;
          bottom: 2px;
          border-left-width: 5px;
          border-bottom-width: 5px;
          border-radius: 0 0 0 5px;
        }
        &:after {
          right: 0;
          bottom: 2px;
          border-right-width: 5px;
          border-bottom-width: 5px;
          border-radius: 0 0 5px 0;
        }
      }
    }
  }

  &-appContainer{
    font-size: 16px;
    display: flex;
    flex-flow: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    margin-top: 32px;

    &-header{
      font-weight: 500;
      font-size: 22px;
    }

    &-buttons{
      min-height: 76px;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
}

.imageEditor{
  max-width: min(80vw, 400px);
}

@keyframes move {
  to {
    transform:translateX(-100%);
    left:100%;
  }
}

@keyframes shadowClip {
   33% {
     clip-path:inset(0 0 0 -100px);    
   }
   50% {
     clip-path:inset(0 0 0 0);    
   }
   83%{
     clip-path:inset(0 -100px 0 0); 
   }
}

@media (--breakpoint-mobile) {
  .paper, .loading-appContainer {
    display: none;
  }
  .clientView-formAndImage-form{
    flex-grow: 1;
  }

  .customerList-entry-content{
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
    gap: 2px 10px;

    &:nth-child(1) {
      grid-template-rows: auto;
      grid-template-columns: auto;
    }
  }

  .compareView-options{
    width: 100%;

    &-title{
      width: 100%;
    }
  }

}

.mobileDropZone {
  &-content {
    border: 2px solid #F0F2F5;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    box-sizing: border-box;
  }

  &-container {
    display: flex; 
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 32px;
  }

  &-takePhotoIcon {
    color: #AAD9D0;
    height: 70px;
    font-size: 70px;
  }

  &-width {
    max-width: 140px;
    width: 100%;
  }

  &-takePhotoContainer {
    padding: 30.5px 10px 36px;
    gap: 20px;
  }

  &-uploadPhotoContainer {
    padding: 30.5px 10px 20px;
    gap: 4px;
  }

  &-heading {
    font-size: 16px;
    font-weight: 600;
  }

  &-description {
    font-size: 10px;
    font-weight: 400;
  }

  &-uploadIcon {
    height: 70px; 
    width: 70px; 
    color: #aad9d0;
    padding-bottom: 16px;
  }
}