.container{
    border: 1px solid #26282D;
    box-sizing: border-box;
    border-radius: 4px;
   
    width: 112px;
    background-color: white;
    z-index: 1;
    color: #26282D;
    line-height: 14px;
    display: flex;
}

@define-mixin center {
    display: flex;
    align-items: center;
}

.country{
    padding: 2px;
    background-color: #003399;
    color: white;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    @mixin center;
    justify-content: center;
    flex-flow: column;
    font-size: 7px;
    line-height: 8px;
    gap: 1px;
}

.emblem{
    padding: 2px 0 2px 2px;
    height: 14px;

    &-container{
        @mixin center;
    }
}

.license{
    padding: 2px 0;
    text-align: center;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}