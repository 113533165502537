@import 'variables.pcss';

.label {
    display: flex;
    width: max-content;
    align-items: center;
    font-weight: 500;

    &-text{
        margin-left: 10px;
    }
}

.badge{
    color: var(--white-color);
    padding: 5px 8px;
    border-radius: 20px;
}

.draft {
    color: var(--message-information-color);
    background-color: var(--message-information-light-color);
}
.imported {
    background-color: var(--draft-color);
}
.preview, .invoice {
    color: #6F6F6F;
    background-color: #E3E6E9;
}
.open {
    color: var(--message-warning-color);
    background-color: var(--message-warning-light-color);
}
.overdue, .withoutCalc, .rejected {
    color: var(--message-error-color);
    background-color: var(--message-error-light-color);
}
.paid, .paid-out, .calculated, .sent, .approved, .registered, .sending {
    color: var(--message-success-color);
    background-color: var(--message-success-light-color);
}

.newOrderArrived {
    color: var(--message-information-color);
    background-color: var(--message-information-light-color);
}

.paidCredit {
    color: var(--status-credit);
    background-color: var(--status-credit-light);
}
.paidCredit .label {
    &-text{
        margin-left: 5px;
    }
}

.active {
    background-color: var(--draft-color);
}

.offer, .active{
    color: #FFB03A;
    background-color: #FFF2DE;
}

.costListing {
    color: var(--secondary-color);
    background-color: #D2DDEE;
}

.customIcon{
    font-size: 14px;
}