@import 'variables.pcss';

.radio {
    &-label{
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
        user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid transparent;
    }

    &-input {
        cursor: pointer;
        appearance: none;
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        border: 1px solid var(--primary-color);
        border-radius: 50%;
        transition: box-shadow 0.3s ease;

        &:focus-visible{
            outline: 1px solid var(--primary-color);
        }

        &:before{
            content: '';
            display: block;
            width: 60%;
            height: 60%;
            margin: 20% auto;
            border-radius: 50%;
        }

        &:checked:before{
            background: var(--primary-color);
        }
      }
}
