.label {
    width: 100%;
    padding: 5px 8px;
    color: var(--white-color);
    display: flex;
    border-radius: 20px;
    align-items: center;
    &-title{
        &-right{
            margin-right: 5px;
        }
        &-left{
            margin-left: 10px;
        }
    }
    &-text{
        font-size: 14px;
        margin-left: auto;
        color: black;
    }
}

.warning {
    color: var(--message-warning-color);
    background-color: var(--message-warning-light-color);
}
.error {
    color: var(--message-error-color);
    background-color: var(--message-error-light-color);
}

.planned, .success {
    color: var(--message-success-color);
    background-color: var(--message-success-light-color);
}
.birthday {
    color: var(--primary-color);
    background-color: var(--primary-lighter-color);
    width: max-content;
}
.info {
    color: var(--message-information-color);
    background-color: var(--message-information-light-color);
}
.delivered {
    color: var(--mark-purple-color);
    background-color: #F4E5FF;
}