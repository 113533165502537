@import 'variables.pcss';


.dataTableModal{
  &-search{
    max-width: 300px;
    padding-bottom: 15px;
  }
  &-footer{
    margin-top: 15px;
    margin-bottom: 0px;
  }
}


.header{
 margin: 5px 5px 7px 5px;
 justify-content: space-between;
 display: flex;
   &-text{
     font-weight: 500;
     font-size: 20px;
     line-height: 24px;
     color: var(--secondary-color);
     margin-right: 10px;
     margin-top: auto;
     margin-bottom: auto;
   }
 
   &-icon{
     color: var(--secondary-color);
     font-size: 20px;
     margin-right: 10px;
     margin-top: auto;
     margin-bottom: auto;

      &-vehicle{
        font-size: 18px;
        vertical-align: bottom;
      }
   }
 
   &-add{
     color: var(--secondary-color);
     font-size: 20px;
     float: right;
     cursor: pointer;
  }

  &-action{
    margin-left: auto;
    position: relative;
  }

  &-cardContainer{
    width: 100% !important;
  }
}

.card{
  &-container{
    padding: 15px 5px 0 5px;
    &-firstEntry{
      padding-top: 2px;
    }
    &-lastEntry{
      padding-bottom: 2px;
    }
  }
}

.columns{
  display: flex;
  gap: 10px;
}

.column{
  overflow: hidden;
  display: flex;
  flex-flow: column;
  
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
}

.tabHeaderNavigation{
  display: flex;
  flex-flow: row;
  width: 100%;
  border-bottom: 2px solid #E3E6E9;
}

.listContainer{
  margin-top: 15px;
}

.listContainerMobile{
  min-height: calc(100vh - 360px);
  max-height: calc(100vh - 360px);
}

.selectButtonContainer{
  display: flex;
  position: relative;
  margin-left: auto;
}

.emptyContent{
  display: flex;
  flex-flow: column;
  gap: var(--spacing-m);
  text-align: center;
  padding: 32px;
  font-size: 16px;
  &-icon{
    display: flex;
    justify-content: flex-end;
  }
  &-text{
    white-space: pre-wrap;
  }
}

.allEmptyContent{
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% - 50px);
  width: 100%;
  z-index: 1;
}

.noEntities{
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% - 50px);
  width: 100%;
  z-index: 1;
  background-color: white;
  &-container{
    position: relative;
  }
  &-button{
      display: flex;
      gap: 8px;
      width: max-content;
      margin: 0 auto 16px auto;
  }
  &-heading{
      font-size: 32px;
      margin-bottom: 32px;
      white-space: pre-wrap;
  }
  &-searchIcon{
      margin-bottom: 32px;
  }
}

@media(--breakpoint-mobile){
  .dataTableModal{
    &-search{
      max-width: unset;
      margin-right: 4px;
    }
  }

  .card{
    &-container{
      padding: 15px 2px 0 2px;
    }
  }

  .header{
    &-action{
      width: 100%;
      margin-top: 15px;
    }
  }
}