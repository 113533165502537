@import 'variables.pcss';

.clientForm {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 64px 64px 64px 64px 64px 64px;
  grid-gap: 4px 64px;
  margin-top: 20px;

  &-element {
    width: 100%;
    
    &-additionalInfo {
      grid-row: span 1;
      height: 100%;
      > label {
        height: 100%;

        > textarea {
          height: 100%;
        }
      }
      &-checkBox{
        margin-top: 15px;
      }
    }

    &-country{
      grid-row: unset;
    }

    &-toAttention {
      grid-row: span 3;
      &-companyOrFamily{
        grid-row: span 4;
        &-edit{
          grid-row: span 3;
        }
      }
      &-edit{
        grid-row: span 2;
      }
    }
    
  }

  @media (--breakpoint-mobile) {
    &-elementCountrySpan {
      grid-row: span 2;
    }
  }

  &-double-element-container {
    display: flex;
    justify-content: space-between;
  }

  &-double-element {
    width: 47% !important;

    &-city {
      width: 59% !important;
    }

    &-zip {
      width: 34% !important;
    }
  }
}

.twoColumnLayout {
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 64px 64px 64px 64px 64px 64px 64px;

  &-italy{
    grid-template-rows: 64px 64px 64px 64px 64px 64px 64px 64px 64px;

    &-withExternalId{
      grid-template-rows: 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px;
    }
  }

  &-element {
    &-toAttention {
      grid-row: unset;
    }
  }

  &-withExternalId{
    grid-template-rows: 64px 64px 64px 64px 64px 64px 64px 64px;
  }
}

@media (--breakpoint-mobile) {
  .clientForm {
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 64px 64px 64px 64px 64px 64px 64px 64px;

    &-element {

      &-toAttention {
        grid-row: unset;
        &-companyOrFamily{
          grid-row: unset;
          &-edit{
            grid-row: unset;
          }
        }
        &-edit{
          grid-row: unset;
        }
      }

      &-country{
        grid-row: span 2;

        &-big {
          grid-row: span 3;
        }
        &-italyLayout{
          grid-row: span 3;
        }
        &-bigAndItalyLayout {
          grid-row: span 4;
        }
      }
    }

    &-withExternalId{
      grid-template-rows: 64px 64px 64px 64px 64px 64px 64px 64px;
    }
    &-italyLayout {
      grid-template-rows: 64px 64px 64px 64px 64px 64px 64px 64px 64px;
      &-withExternalId{
        grid-template-rows: 64px 64px 64px 64px 64px 64px 64px 64px 64px 64px;
      }
    }
  }
}

@media (--breakpoint-tablet) {
  .clientForm {
    justify-items: center;
    grid-template-columns: 100%;
    grid-template-rows: repeat(15, 64px);


    &-element {

      width: 95%;
  
      &-toAttention {
        grid-row: unset;
      }
      &-country{
        grid-row: unset;
        &-big {
          grid-row: unset;
        }
        &-italyLayout{
          grid-row: unset;
        }
        &-bigAndItalyLayout {
          grid-row: unset;
        }
      }
    }

    &-withExternalId{
      grid-template-rows: repeat(16, 64px);
    }
    &-italyLayout {
      grid-template-rows: repeat(16, 64px);
      &-withExternalId{
        grid-template-rows: repeat(17, 64px);
      }
    }
  }
}

.editExternalId {
  display: inline-block;
  overflow-wrap: break-word;
  max-width: 100%;

  &-icon {
    cursor: pointer;
    margin-left: 2px;
  }
}