.tooltip {
  &-balance {
    width: 180px;
    text-align: right;
    padding-right: 0;
    line-height: 18px;
    font-size: 12px;
  }
}

.circleTooltip {
  position: absolute;
  width: 25px;
  height: 15px;
  top: 0;
  left: 0;
  &-row{
    display: flex;
    gap: 5px;
    text-align: right;
    justify-content: flex-end;
    &-data{
      display: table-cell;
      &-balance{
        padding: 0 8px;
        width: 45%;
        justify-content: flex-end;
      }
    }
  }
}

.pieChart {
  position: absolute;
  top: -2px;
  right: 0px;
}