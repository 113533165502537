.imageEditor{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: gray;
    cursor: move;

    &-zoomButtons{
        position: absolute;
        top: 9px;
        right: 9px;
        display: flex;
        flex-flow: row;
        gap: 6px;
        flex-flow: column;

        & > * {
            width: 30px;
            height: 30px;
            justify-content: center;
        }
    }

    &-image{
        max-width: 100%;
        user-select: none;
        max-height: 80vh;
    }

    &-container{
        position: absolute;
        transform-origin: 0 0;
    }

    &-header{
        display: flex;
        font-size: 16px;
        white-space: pre-wrap;
        margin-bottom: 38px;
        gap: 15px;
    }

    &-rotate{
        padding: 10px;

        &-reverse{
            transform: scaleX(-1);
        }
    }
}

.container{
    min-width: max(100%, 240px);
}

@media print{
    .imageEditor{
        &-container{
            justify-content: center;
            display: flex;
            position: relative;
            transform: unset !important;
        }
        &-zoomButtons{
            display: none;
        }
        &-image{
            visibility: visible;
        }
    }
}