.containerCard{
    &-element{
      align-items: center;
      vertical-align: middle;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 16px;
      color: #26282D;
      font-weight: normal;
      display: inline-flex;
      padding: 0;
      line-height: 20px;
    }
  }

.icon{
  color: #4F6374;
  padding-right: 15px;
  align-self: center;
  font-size: 18px;
  width: 30px;
  justify-content: center;
  display: flex;
}

.select{
  &-container{
    width: max-content;
    float: right;
    background-color: white;
    border-radius: 6px;
    box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
    
  }

  &-align{
    &-left {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }

  &-element{
    background-color: white;
    border-radius: 6px;
    padding: 12px;
    margin: 10px;
    text-align: left;
    cursor: pointer;

    &-disabled{
      cursor: not-allowed;
      filter: brightness(1.2);
    }

    &:not(&-disabled):hover{
        background-color: #e3efed;
    }
  }
  
  &-elemnt:checked{
    background-color: #AAD9D0;
  }
}

.container{
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  margin-top: 10px;
}

@keyframes fade-in {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-10px);
    opacity: 0;
  }
}



.fade-in {
  animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-out {
  animation: fade-out 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}