@import 'variables.pcss';

.notification-button {
  @mixin link-font;
  border-radius: 2px 2px;
  cursor: pointer;
  color: white;
  width: 100%;
  text-transform: uppercase;
  padding: 0.5em 0.5em;
  font-size: 0.8em;
}

.notification-button:hover{
  color: white;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.5);
}
