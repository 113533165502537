@import 'variables.pcss';

.containerCard{
    justify-content: space-between;
    padding: 5px 0 15px 0;
    background: #FFFFFF;
    box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
    border-radius: 8px;
    overflow: hidden;
    display: flex;

      &-selected{
        background-color: var(--primary-lighter-color);
      }

      &-hover:hover{
        background-color: #E3EFED;
        cursor: pointer;
      }

      &-deleted{
        background-color: var(--grey-9-color);
      }

      &-onRemove{
        background-color: var(--message-error-light-color);
        transition: background-color 0.3s ease-in-out;
      }
  
      &-details{
        display: block;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px 15px 0;
        background: #FFFFFF;
        box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
        border-radius: 8px;
        overflow: hidden;
        cursor: default;

        &-row{
          display: flex;
          justify-content: space-between;
          line-height: 28px;

          &-container{
            display: flex;
          }

          &-icon{
            font-size: 17px;
          }

          &-element{
            display: inline-flex;
          }

          &-block{
            width: 100%;
          }
        }
      }

      &-listRow{
        align-items: center;
        padding: 12px 0px 12px 0px;
        &:hover{
          background-color: #E3EFED;
          cursor: pointer;
        }
      }
  
      &-data{
        display: inline-block;
        height: 100%;
        vertical-align: top;
        max-width: 85%;

        &-details{
            display: inline-block;
            height: 100%;
            vertical-align: top;
            width: 47%;
        }
      }
    
      &-element{
        align-items: center;
        vertical-align: middle;
        white-space: pre-wrap;
        font-size: 12px;
        line-height: 20px;
        color: #6F6F6F;
        font-weight: normal;
        display: flex;
        padding: 10px 0 0 15px;
        font-weight: 400;

        &-fullLine{
          width: 100%;
        }

        &-ellipsis{
          max-height: 1lh;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &-list{
          font-size: 14px;
          align-items: center;
          vertical-align: middle;
          white-space: pre-wrap;
          line-height: 20px;
          color: #26282D;
          font-weight: normal;
          display: flex;
          padding: 10px 0 0 15px;
          font-weight: 400;

          &-row{
            justify-content: space-between;
            display: flex;
            line-height: 28px;
            padding: 10px 0 0 15px;

            &-item{
              display: inline-flex;

              &-right{
                margin-right: 60px;
                font-size: 14px;
              }
            }
          }
        }

        &-item{
          line-height: 28px !important;
        }

        &-name{
          font-weight: 500;
          font-size: 16px;
        }

        &-inline{
            display: inline-flex;
        }

        &-bold{
          font-size: 14px !important;
          color: #26282D !important;
        }

        &-small{
          font-size: 12px;
          color: #6F6F6F;
          margin-right: 15px;
        }
  
        &-right{
          vertical-align: middle;
          padding-left: 15px;
          font-size: 14px;
          color: #26282D;
          font-weight: normal;
          white-space: pre-wrap;
          display: flex;
          line-height: 20px;
          float: right;
          margin-left: auto;
        }

        &-actions{
          justify-content: flex-end;
          display: flex;
          padding: 15px 15px 0 0;
          gap: 10px;

          &-noPadding{
            padding: 0;
          }

          &-details{
            padding: 0 0 10px 0;
          }
        }
      }
      &-iconAndActions{
        display: flex;
        flex-flow: column;
      }
}

.rowCard{
  display: flex;
  padding: 15px;
  font-size: 14px;
  gap: 10px;

  &-mobile{
    display: flex;
    flex-flow: column;
    padding: 15px 15px 0 15px;
    font-size: 14px;
    gap: 10px;
    width: 100%;
  }

  &-block{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

    &-mobile{
      flex-flow: row;
      width: 100%;
    }
  }

  &-wrap{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

    &-right{
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: end;
    }
  }

  &-title{
    font-weight: 500;
    font-size: 16px;

    &-container{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
    }
  }
  
  &-element{
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &-rimContainer{
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 2.5px 8px;
    background-color: white;
    align-items: center;
    display: flex;
    gap: 10px;
  }

  &-tireIcon{
    color: var(--secondary-color);
    display: flex;
  }

  &-mileage{
    display: flex;
    align-items: center;

    &-old{
      color: var(--grey-5-color);
    }
  }

  &-inspection{
    width: unset;
  }
}

.header{
  &-element{
    align-items: center;
    vertical-align: middle;
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 20px;
    color: #6F6F6F;
    font-weight: normal;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 400;
  }

  &-title{
    display: flex;
    padding-top: 5px;
  }
  &-icon{
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
    color: #42948833;
    float: left;
    margin-left: -35px;
    padding-top: 10px;
  }

  &-name{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 5px 0 0 15px;

    &-withoutLink{
      color: var(--secondary-color)
    }
  }

  &-license{
    padding-top: 5px;
  }

  &-client{
    color: #429488;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0 5px 15px;
    cursor: pointer;
    display: flex;

    &-icon{
      color: #429488;
      margin-right: 5px;
    }
  }

  &-text{
    font-size: 12px;
    padding-top: 0;
    font-weight: 400;
  }

  &-right{
    float: right;
    margin-left: auto;
    display: flow-root;
    padding-top: 10px;
  }

  &-seperator{
    background-color: #E0E0E0;
    height: 1px;
    width: 100%;
    padding-right: 15px;
  }
}
  
.icon{
  color: #4F6374;
  padding-right: 15px;
  font-size: 14px;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  
  &-check{
    color: #4F6374;
    font-size: 18px;
  }

  &-action{
    font-size: 16px;
    padding: 0;
    cursor: pointer;

    &-remove:hover{
      color: var(--message-error-color)
    }
  }

  &-row{
    padding-right: 5px;
  }
}

.containerMileage{
  width: 100%;
  padding: 15px 0;
  display: inline-flex;
  justify-content: space-between;

  &-content{
    display: flex;
    flex-flow: column;
    gap: 5px;
  }
  &-text{
    align-items: center;
    vertical-align: middle;
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 20px;
    color: #6F6F6F;
    font-weight: normal;
    display: inline-flex;
    font-weight: 400;
    padding-left: 15px;
  }

  &-value{
    display: flex;
    justify-content: end;
  }

  &-button{
    margin-left: auto;
  }

  &-updatedOn{
    color: var(--grey-4-color);
  }
}

.containerImage{
  &-vehicle{
    display: inline-block;
    font-size: 60px;
    overflow: hidden;
    vertical-align: bottom;
    color: #42948833;
    float: right;
    width: 40px;

    &-details{
      display: inline-block;
      vertical-align: bottom;
      color: #42948833;
      width: 55px;
      font-size: 80px;
      padding-top: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-list{
      display: flex;
      color: #42948833;
      font-size: 70px; 
      width: 50px;
      height: 100%;

      
      &-selected{
        color: #FFFFFF;
        transition: color 0.3s ease-in-out;
      }
    }

    &-icon{
      vertical-align: bottom;
      transform: scaleX(-1);
      margin-top: auto;
      display: flex;
    }
  }
  &-icon{
    vertical-align: bottom;
      width: 60px;
      height: 70px;
      transform: rotate(0, 90deg);
      transform:scale(-1 1);
  }
}

.vehicleVIN{
  margin-right: 15px;
  color: #4F6374;
  font-size: 10px;
  font-weight: bold;
}

.containerAction{
  margin-bottom: 10px;
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: end;
  gap: 10px;
  &-icon{
    color: #4F6374;
  }
}

.seperator{
  background-color: #E0E0E0;
  height: 1px;
  margin: 10px 0 0 15px;
}

.selectButton{
  padding: 0;
}

.ebv{
  padding: 6px 8px;
}

.licenseContainer{
  height: min-content;
  margin-top: auto;
  margin-bottom: auto;
}

.vehicleCardForm{
  min-width: 312px;
  display: grid;
  grid-auto-flow: column;
  grid-template-column: repeat(2, 2fr);
  &-row{
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-end;
    gap: 10px;

    &-block{
      display: inline;
      flex-flow: column;
    }

    &-icon{
      color: #4F6374;
    }
  
    & > * {
      width: 100%;
    }
  }
}

.truncateFormat{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60vw;

  &-card{
    &-name{
      max-width: 70vw;
    }
  }
}

.link{
  @mixin link-modern;
}

.tireList{
  padding: 15px 0;
  &-header{
    &-container{
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 0 15px;
    }
    &-title{
      line-height: 24px;
      font-size: 20px;
      color: #4f6374;
      font-weight: 500;
    }

    &-icon{
      color: #4F6374;
      font-size: 20px;
      margin-right: 10px;
    }

    &-add{
      margin-left: auto;
    }
  }
}

.totalAmountCircle{
  font-size: 10px;
  border-radius: 50%;
  border: 5px solid #3e596c;
  width: auto;
  min-width: 10px;
  height: 10px;
  background-color: #3e596c;
  color: #FFFFFF;
  text-align: center;
  line-height: 10px;
  display: inline-block;
  vertical-align: text-bottom;
  font-weight: bold;
}

@media (--breakpoint-mobile) {
  .vehicleCardForm{
    grid-template-columns: 1;
    &-row{
  
      &-block{
        display: flex;
        grid-row: span 0;
      }
    }
  }

  .containerCard{
    &-dualData{
      width: 100% !important;
    }

    &-oneColumn{
      border-radius: unset;
    }
  }

  .tireList{
    padding: 0;
    &-header{
      &-container{
        padding: 15px;
      }
    }
  }
}
