@import 'variables.pcss';

.selectButton{
  user-select: none;
  width: fit-content;
  max-width: 100%;

  &-open{
    color: var(--message-information-color);
    width: 20px;
    height: 20px;
    transform: scale(-1, 1);
  
    &:hover{
      color: #5eabfd;
    }
  }
  
  &-cancel{
    &:hover{
      color: #576c7f;
    }
  }

  &-text{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &-disabled{
    pointer-events: none !important;
    cursor: not-allowed;
  }

  &-notAllowed{
    cursor: not-allowed;
  }
}