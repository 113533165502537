@import 'variables.pcss';

.containerCard{
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 5px 0 15px 0;
    background: #FFFFFF;
    box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

      &-border{
        box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
        border-radius: 8px;
        background: #FFFFFF;

        &-selected{
          background-color: var(--primary-lighter-color)
        }
      }

      &-hover:hover{
        background-color: #E3EFED;
        cursor: pointer;
      }

      &-row{
        align-items: center;
        padding: 5px 0 12px 0;
        width: 100%;
      }
      &-deleted{
        background-color: var(--grey-9-color);
      }
    
      &-element{
        box-sizing: border-box;
        width: 90%;
        align-items: center;
        vertical-align: middle;
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 16px;
        color: #26282D;
        font-weight: normal;
        display: flex;
        padding: 10px 20px 0 15px;
        line-height: 20px;
        justify-content: space-between;

        &-full-line{
          width: 100%;
          padding-right: 0;
        }

        &-fullLineWithPadding{
          width: 100%;
        }

        &-inline{
          display: inline-flex;
        }
        
        &-name{
          font-weight: 500;
          font-size: 16px;
          padding: 15px 0 0 15px;
        }

        &-dunning{
          display: flex;
          align-items: center;
          gap: 10px;
        }
  
        &-border{
          border: 1px solid #26282D;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 0 3px;
          width: 102px;
          text-align: center;
          background-color: white;
          z-index: 1;
          justify-content: center;
          height: min-content;
          align-self: center;
        }

        &-actions{
          justify-content: flex-end;
          display: flex;
          padding-right: 15px;
          gap: 10px;
        }
      }
    }
  
  .icon{
    color: #4F6374;
    padding-right: 15px;
    align-self: center;

    &-action{
      font-size: 16px;
      padding: 0;
      cursor: pointer;
    }
  }
  
  .containerImage{
    &-invoice{
      display: inline-block;
      width: 40px;
      float: right;
      font-size: 50px;
      overflow: hidden;
      vertical-align: bottom;
      color: #42948833;
      margin-top: -60px;
      display: inline-flex;
      justify-content: center;
      &-icon{
        vertical-align: bottom;
        transform: scaleX(-1);
        margin-left: 20px;
       }
      &-iconEuro{
      vertical-align: bottom;
      }

      &-selected{
        color: #FFFFFF;
      }
    }
  }
  
  .containerAction{
    margin-left: auto;
    background: #F0F2F5;
    border-radius: 6px;
    padding: 6px;
    margin-bottom: 10px;
    cursor: pointer;
    &-icon{
      color: #4F6374;
    }
  }

  .header{
    &-icon{
      display: inline-block;
      font-size: 110px;
      overflow: hidden;
      vertical-align: bottom;
      color: #42948833;
      float: left;
    }
  }
  
  .truncateFormat{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60vw;
  }
  
  .stripeContainer{
    width: 8px;
    display: table-cell;
    height: 100%;
  }
  
  .stripe{
    background-color: #EE762F;
    height: 100%;
    padding-bottom: 15px;
  }
  
  .dunning{
    display: inline-flex;
    width: 22px;
    height: 22px;
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
  
    &-even{
        margin-top: 2px;
    }
  
    &-reminder{
        background-color: #008000;
    }
    &-first{
        background-color: #FFCF00;
    }
    &-second{
        background-color: #EE762F;
    }
    &-third{
        background-color: #E52717;
    }
  
    &-tooltip{
        &-row{
            text-align: center;
        }
        &-text{
            display: inline-flex;
            margin-left: 10px;
        }
    }
  }
  
  
  
  .tooltip {
    &-dunning{
        width: 150px;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
        line-height: 18px;
    }
}

  .circlePlaceHolder{
    margin-right: 32px;
  }

  .seperator{
    background-color: #E0E0E0;
    height: 1px;
    width: 100%;
  }

  .table{
    &-row{
      &:nth-child(2n) {
        background-color: var(--dark-table-background-color);
      }
      &:hover{
        background-color: #E3EFED;
        cursor: pointer;
      }
    }
  }

  .containerVehicle{
    display: flex;

    &-license{
      margin-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-model{
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .tdStatus{
    width: 18%;
  }

  .tdNumber{
    width: 8%
  }

  .tdDate{
    width: 11%
  }

  .tdVehicle{
    width: 35%
  }

  .tdSum{
    width: 17%;
    text-align: right;

    &-header > div{
      margin-right: 25px;
    }
  }

  .gross{
    position: relative;
    padding-right: 25px;
  }

  .pieChart{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-right: 5px;
  }