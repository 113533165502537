@import 'variables.pcss';

.table {
  border-collapse: collapse;
  width: 100%;
  display: flex;
  flex-flow: column;

  &-tabHeader {
    &-disabled{
      cursor: unset;
      pointer-events: none;
    }
    &-disabled > button { 
      color: rgba(0,0,0,.87) !important;
    }
  }
  
  &-tabHeader > button {
      color: var(--main-color);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
  }

  &-header {
    font-size: 14px;
    font-weight: 500;
    display: table;
    background-color: #F0F2F5;
    border-bottom: 1px solid var(--border-color);

    &-row{
      font-size: 14px;
      height: 40px;
      display: table;
      table-layout: fixed;
      width: 100%;
      color: #4F6374;
    }

    &-border{
      border-bottom: 1px solid var(--border-color);
    }

    &-hidden{
      display: none;
    }
  }

  &-body {
    @media (max-height: 1199px) {
      max-height: 62vh;
    }
    @media (min-height: 1200px) {
      max-height: 75vh;
    }
    overflow: auto;
    flex: 1;
    width: 100%;
  }

  &-row {
    @mixin tabletext-font;
    height: 40px;
    background-color: inherit;
    display: table;
    table-layout: fixed;
    width: 100%;
    vertical-align: middle;
    cursor: pointer;

    &-notClickable{
      cursor: default;
    }
    &-selected{
        background-color: #E3EFED;
    }
    &-header {
        background-color: #F0F2F5;
        width: 100%;
        table-layout: fixed;
        display: table;
        height: 38px;
    }
    &:hover {
        background-color:  #E3EFED;
    }
    &-noHover:hover{
      background-color: inherit;
      cursor: default;
    }
    &-hoverLastElement span:last-child {
        visibility: hidden;
      }
      
    &-hoverLastElement:hover span:last-child{
        visibility: visible;
    }

    &-hoverLastElementButton button:last-child {
      visibility: hidden;
      transition: none;
    }

    &-hoverLastElementButton:hover button:last-child{
        visibility: visible;
        transition: none;
    }
  }

  &-element {
    padding: 0px 12px;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }

  &-actions {
    padding: 0;
  }

  &-pdf-actions {
    width: 65px;
    padding: 5px 20px 5px 10px;
    color: var(--primary-font-color);
  }

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }

  &-sub-element {
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    width: 100%;
  }


  &-element-right {
    padding: 7.5px 30px 7.5px 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-narrow-column {
    width: 75px;
  }

  &-selector {
    @media (min-width:1251px) {
      width: 40px;
    }
    @media (min-width:901px) and (max-width:1250px) {
      width: 20px;
    }
    @media (max-width:900px) {
      width: 20px;
    }
    padding: 5px 20px 5px 10px;
    color: var(--primary-font-color);
  }

  &-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 2;

    &-action {
      float: right;
      color: var(--action-button-color);
      padding: 5px 3px;
      margin: 3px;

      &:hover {
        color: #8c8c8c;
      }
    }

    &-disabled {
      float: right;
      color: var(--light-background-color);
      filter: brightness(0.90);
      padding: 5px 10px;
      border-radius: 2px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
      margin: 3px;
      cursor: not-allowed;
    }
  }

  &-checkbox {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      z-index: 2;
      margin-right: 10px;

      &-action {
        float: right;
      }

      &:hover {
        color: #333;
      }
    }

    &-text {
      font-family: sans-serif;
      line-height: 20px;
    }
  }

  &-icons-element {
    width: 48px;
  }

  &-checkbox {
    width: 25px;
  }

  &-form-of-address {
    width: 55px;
  }

  &-date-of-birthday {
    width: 95px;
  }

  &-remove {
    width: 30px;
  }

  &-date-of-registration {
    width: 95px;
  }

  &-mileage {
    width: 80px;
  }

  &-number {
    width: 65px;
  }

  &-created-on {
    width: 100px;
  }

  &-status {
    width: 125px;
  }

  &-invoice-date {
    width: 75px;
  }

  &-due-date {
    width: 95px;
  }

  &-refers-to {
    width: 120px;
  }

  &-register-icons {
    width: 160px;
    padding: 0;
  }

  &-paid-on {
    width: 100px;
  }

  &-invoice-number {
    width: 150px;

    @media (max-width:1400px) {
      width: 100px;
    }
  }

  &-quick-margin {
    width: 100px;
  }

  &-amount {
    width: 150px;
  }
}

@media print {
  .table-selector,
  .table-actions,
  .table-remove,
  .table-actions,
  .table-icon,
  .table-icons-element,
  .table-register-icons {
    display: none;
  }

  .table-element{
    div span div{
      display: none;
    }
  }

  table {
   position: absolute;
   top: 50;
   page-break-after:auto;
  }

  table tbody tr    {
    page-break-inside:avoid; 
    page-break-after:auto;
    margin: 2px 0;
  }
  table tbody tr td    { page-break-inside:avoid; page-break-after:auto;}

  @-moz-document url-prefix() {
    table tbody tr   { display: inline-table !important; width: 100%; }
    table tbody   { display: table !important; width: 100%; }
    table   { border-collapse: unset !important; }
  }

  .table-element {
    padding: 0;
  }

  .table-body {
    max-height: none;
  }
}
