@import 'variables.pcss';

.containerCard{
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 5px 0 15px 0;
    background: #FFFFFF;
    box-shadow: 1px 1px 5px rgba(79, 99, 116, 0.3);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
  
      &-data{
        display: inline-block;
        height: 100%;
        width: 100%;
        vertical-align: top;
      }

      &-row{
        align-items: center;
        padding: 12px 0px;
        &:hover{
          background-color: #E3EFED;
          cursor: pointer;
        }
      }
    
      &-element{
        align-items: center;
        vertical-align: middle;
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 16px;
        color: #26282D;
        font-weight: normal;
        display: inline-flex;
        padding: 10px 0 0 15px;
        line-height: 20px;

        &-right{
          float: right;
          display: inline-block;
        }
      }
    }

  .containerImage{
    &-tire{
      display: inline-block;
      font-size: 65px;
      overflow: hidden;
      vertical-align: bottom;
      color: #42948833;
      float: right;
      width: 38px;
      margin-top: -75px;
    }

    &-icon{
      vertical-align: bottom;
        width: 60px;
        height: 70px;
        transform: rotate(0, 90deg);
        transform:scale(-1 1);
    }
  }
  
  .seperator{
    background-color: #E0E0E0;
    height: 1px;
  }

  .truncateFormat{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40vw;
    white-space: nowrap;
  }
  
  @media (min-width: 900px) {
    .truncateFormat{
      max-width: 19vw !important;
    }
  } 
  
  @media (min-width: 1150px) {
    .truncateFormat{
      max-width: 10vw !important;
    }
  }  
  

.tire{
  &-container{
    padding: 5px 10px;
    font-size: 12px;
    display: inline-block;
  }
  
  &-rimContainer{
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 2.5px 8px;
    background-color: white;
    align-items: center;
    display: flex;

    &-margin{
      margin-right: 50px;
    }
  }
  
  &-inStorage{
    background-color: #E3EFED;
    border-radius: 20px;
    padding-right: 10px;
    color: #429488;
    display: flex;
    align-items: center;

    &-icon{
      color: #429488;
      padding-right: 5px;
    }
  }
  
  &-outStorage{
    background-color: #E0E0E0;
    border-radius: 20px;
    padding-right: 10px;
    color: #6F6F6F;
    display: flex;
    align-items: center;
  
    &-icon{
      color: #6F6F6F;
      padding-right: 5px;
    }
  }

  &-type{
    display: block;
    padding-left: 0;

    &-element{
      padding-top: 5px;
    }

    &-icon{
      margin-right: 5px;
      margin-top: 4px;
      color: var(--secondary-color);
    }
  }

  &-status{
    margin-right: 50px;
    padding-top: 0;

    &-field{
      padding: 5px 10px;
      border-radius: 20px;
    }
  }
}

.tireList{
  &-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #E0E0E0;
    &:hover{
      background-color: #E3EFED;
      cursor: pointer;
    }

    &-block{
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 0 15px;
    }

    &-element{
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}

.storageIcon{
  margin-right: 10px;
}


.icon {
  margin-right: 6px;
  &-Red{    
    color: var(--message-error-color);
  }
  &-Orange{    
    color: var(--message-warning-color);
  }
  &-Green{
    color: var(--message-success-color);
  }
}

.status_orange{ 
  background: var(--message-warning-light-color);
  color: var(--message-warning-color);
}

.status_red{
  background: var(--message-error-light-color);
  color: var(--message-error-color);
}

.status_green{  
  background: var(--message-success-light-color);
  color: var(--message-success-color);
}