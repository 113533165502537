@import 'variables.pcss';

.full-image-view {
  &-container{
    width: 450px;
  }

  &-image {
    max-width: 100%;
  }

  &-footer{
    padding: 15px 0px 0px;
  }

  &-content {
    overflow-y: auto;
    max-height: 100%;
    width: 100%;
  }
}

.paper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 20px;
  background-color: var(--grey-8-color);
  border-radius: 8px;
  width: 324px;

  &-flex {
    flex: 1;
    margin-left: auto;
    margin-right: auto;
  }

  &-picture {
    max-width: 324px !important;
    max-height: 446px;

    canvas {
      max-width: 324px !important;
      max-height: 1000px !important;
      object-fit: contain !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 100;
    }
  }

  &-pages{
    position: relative;
    display: flex;
    gap: 15px;
    justify-content: center;
    padding-top: 14px;
    flex-wrap: wrap;
  }
}

.imageContainer{
  min-width: 40%;
}

@media print{
  @page {margin:0mm;}
  .full-image-view  {
    &-footer{
      display: none;
    }
  }

  .paper{
    &-pages{
      display: none;
    }
  }

  .imageContainer{
    visibility: hidden;
    width: 100%;
    position: absolute;
    padding: 0;
  }

  .imageContainer > div > div > div{
    visibility: visible;
    display: contents;
  }
}

