@import 'variables.pcss';

.dropZone{
    &-container{
        height: 100%;
        width: 100%;
        box-sizing: border-box;

        &-clickable{
            cursor: pointer;
        }

        &-active{
            border: 2px dashed var(--primary-color);
            background-color: var(--primary-lighter-transparent-color);
        }

        &-idle{
            border: 2px dashed var(--grey-6-color);

            &:hover{
                border: 2px dashed var(--primary-color);
                background-color: var(--primary-lighter-transparent-color);
            }
        }

        &-error{
            border: 1px dashed red;

            &:hover{
                border: 2px dashed red;
                background-color: #339afb1a;
            }
        }

        &-events{
            &-enabled{
                pointer-events: all;
            }
            &-disabled{
                pointer-events: none;
            }
        }
    }

    &-filePicker{
        display: none;
    }
}
